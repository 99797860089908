import { Dispatch } from "redux";
import axios from "axios";
import { mainURL } from "../config";

  export type ProductStateType = {
    id: number;
    name: string;
    price: number;
    description: string;
    is_active: boolean;
    image: string;
  };

export type SetProductsActionType = {
    type: 'SET_PRODUCTS';
    products: ProductStateType[];
}

export type ProductActionType = SetProductsActionType;

export const ProductReducer = (state: ProductStateType[] = [], action: ProductActionType) => {
  switch (action.type) {
    case 'SET_PRODUCTS':
        return action.products;
    default:
      return state;
  }
};

export const setProductsAC = (products: ProductStateType[]): SetProductsActionType => {
    return {
      type: 'SET_PRODUCTS',
      products,
    };
}

export const getProductsTC = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axios.get(mainURL + '/en/api/v1/shops/products/');
            const products = response.data.results.map((product: any) => {
                return mapProductFromAPI(product);
            })
            dispatch(setProductsAC(products));
        } catch(error) {
            console.error('Unexpected error:', error);
        }
    }
}


const mapProductFromAPI = (data: any): ProductStateType => {
    return {
      id: data.units[0].id,
      name: data.name,
      price: data.units[0].price,
      description: data.units[0].description || data.description, 
      is_active: data.is_active,
      image: data.units[0].images[0]?.image || '', 
    };
  };