import { jwtDecode} from 'jwt-decode';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface JwtPayload {
  exp: number; 
}


export const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  let token = localStorage.getItem('accessToken');

  useEffect(() => {
    token = localStorage.getItem('accessToken');
    setIsAuthenticated(!!token);

    if (token) {
      try {
        const decoded = jwtDecode<JwtPayload>(token);
        const isExpired = decoded.exp * 1000 < Date.now(); 
        if (isExpired) {
          localStorage.removeItem('accessToken'); 
          navigate('/', { replace: true }); 
        }
      } catch (error) {
        console.error("Failed to decode or validate token", error);
        navigate('/', { replace: true });
      }
    } else {
      navigate('/', { replace: true });
    }
  }, [token]);

  const logout = () => {
    localStorage.removeItem('accessToken');
    setIsAuthenticated(false);
    window.location.reload();
  };

  

  return {
    isAuthenticated,
    logout,
  };
};
