import React, { useEffect } from 'react';
import './app.css';
import { useDispatch, useSelector } from 'react-redux';
import { getCategoriesTC } from './state/category-reducer';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainPage from './pages/main/MainPage';
import { getProductsTC } from './state/product-reducer';
import { CartItemType, getCartItemsTC } from './state/cart-reducer';
import CartPage from './pages/cart/CartPage';
import { RootState } from './state/store';
import { getSubcategoriesTC } from './state/subcategory-reducer';

const App = () => {
  const dispatch = useDispatch<any>();

  const cartItems = useSelector<RootState, CartItemType[]>(
    (state) => state.cartItems
  );

  useEffect(() => {
    dispatch(getCategoriesTC());
    dispatch(getProductsTC());
    dispatch(getCartItemsTC());
    dispatch(getSubcategoriesTC());
  }, [dispatch]);

  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/shopping-cart" element={<CartPage />} />
          <Route path="/shopping-cart/:orderId" element={<CartPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
