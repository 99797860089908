import { useSelector } from 'react-redux';
import './category-list.css';
import { RootState } from '../../state/store';
import { CategoryStateType, CategoryType } from '../../state/category-reducer';
import CategoryItem from '../category-item/CategoryItem';

type CategoryListPropsType = {
  activeCategory: CategoryType | null;
};

const CategoryList = (props: CategoryListPropsType) => {
  const { categories: categoryList } = useSelector<
    RootState,
    CategoryStateType
  >((state) => state.categories);

  console.log('activeCategory', props.activeCategory);

  return (
    <div className="categories">
      <div className="category-list">
        {categoryList.length > 0 ? (
          categoryList.map((category) => (
            <CategoryItem
              category={category}
              key={category.id}
              isActive={props.activeCategory?.id === category.id}
            />
          ))
        ) : (
          <p>No categories available</p>
        )}
      </div>
    </div>
  );
};

export default CategoryList;
