import { useEffect, useState } from 'react';
import { mainURL } from '../../config';
import {
  CartItemType,
  decreaseUnitAmountTC,
  increaseUnitAmountTC,
  removeFromCartTC,
} from '../../state/cart-reducer';
import './cart-item.css';
import { useDispatch } from 'react-redux';
import { ShowModalType } from '../show-modal/ShowModal';
import { useLocation, useNavigate } from 'react-router-dom';
import Map, { Marker } from 'react-map-gl';
import { fetchWithAuthCheck } from '../utils/fetch';
import axios from 'axios';

type CartItemsPropsType = {
  cartItem: CartItemType;
  isSelected: boolean;
  isExpanded: boolean;
  isAnyExpanded: boolean;
  onSelect: () => void;
  changeModalType: (modalType: ShowModalType) => void;
  onToggleUnits: () => void;
};

type GoogleMapsLocation = {
  lat: number;
  lng: number;
};

type UserAddressType = {
  id: number;
  address_string: string;
  lat: number;
  lng: number;
};

const CartItem = (props: CartItemsPropsType) => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const location = useLocation();

  const [selfPickup, setSelfPickup] = useState(true);
  const [userAddresses, setUserAddresses] = useState<UserAddressType[]>([]);
  const [selectedAddress, setSelectedAddress] = useState('');
  const [showMap, setShowMap] = useState(false);
  const [defaultLatitude, setDefaultLatitude] = useState(43.237376);
  const [defaultLongitude, setDefaultLongitude] = useState(76.8638976);
  const [newLatitude, setNewLatitude] = useState('');
  const [newLongitude, setNewLongitude] = useState('');
  const [zoom, setZoom] = useState(15);
  const [addressFromInput, setAddressFromInput] = useState('');
  const [addressIsAdded, setAddressIsAdded] = useState(false);
  const [map, setMap] = useState<google.maps.Map | null>(null);

  const handleIncreaseUnitAmount = (unitId: number) => {
    dispatch(increaseUnitAmountTC(unitId));
  };

  const handleDecreaseUnitAmount = (unitId: number, unitQuantity: number) => {
    if (unitQuantity === 1) {
      dispatch(removeFromCartTC(unitId));
      props.onToggleUnits();
    } else {
      dispatch(decreaseUnitAmountTC(unitId));
    }
  };

  const handleChangeModalType = () => {
    props.changeModalType('credit-card');
    navigate(`/shopping-cart/${props.cartItem.id}`);
  };

  const handleChangeAddressFromInput = (e: any) => {
    setAddressFromInput(e.target.value);
  };

  const updateSelfPickup = async (pickup: boolean) => {
    try {
      const response = await axios.patch(
        mainURL + `/en/api/v1/shops/orders/${props.cartItem.id}/`,
        {
          self_pickup: pickup,
          address_id: addressFromInput,
        }
      );

      setSelfPickup(pickup);
    } catch (error) {
      console.error('Error updating delivery method:', error);
    }
  };

  const updateDefaultAddress = async (addressId: number) => {
    try {
      await axios.patch(mainURL + `/api/v1/users/addresses/${addressId}/`, {
        is_default: true,
      });
    } catch (error) {
      console.error('Error updating address:', error);
    }
  };

  const addNewAddress = async () => {
    if (!addressFromInput.trim() || !selectedAddress) {
      alert('Пожалуйста, выберите адрес для добавления.');
      return;
    }

    try {
      const response = await axios.post(mainURL + `/en/api/v1/users/addresses/`, {
        city_id: 1,
        address_string: selectedAddress,
        detail_info: addressFromInput,
        lat: newLatitude,
        lng: newLongitude,
        is_default: true,
      });
      if (response.status === 201) {
        setAddressIsAdded(true);
        setAddressFromInput('');
        setTimeout(() => setAddressIsAdded(false), 1500);
      }
    } catch (error) {
      console.error('Error adding new address:', error);
    }
  };

  const fetchAddressByLocation = async (lat: number, lng: number) => {
    const token = localStorage.getItem('accessToken');
    try {
      const response = await axios.get(
        mainURL + `/en/api/v1/users/get-address-by-location/?lat=${lat}&lng=${lng}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      setSelectedAddress(response.data.address);
    } catch (error) {
      console.error('Error fetching address:', error);
    }
  };

  useEffect(() => {
    updateSelfPickup(true);
  }, []);

  return (
    <>
      <div
        className={`cart-item ${props.isExpanded ? 'expanded' : ''}`}
        onClick={props.onToggleUnits}
        style={{ opacity: !props.isAnyExpanded || props.isExpanded ? 1 : 0.5 }}
      >
        <img src={mainURL + props.cartItem.shop.logo} alt="" />

        <div className="cart-item-info">
          <p className="shop-name">{props.cartItem.shop.name}</p>
        </div>

        <div className="cart-item-cost">
          <p className="item-cost">{props.cartItem.order_total_price} ₸</p>
        </div>

        <p className="amount">{props.cartItem.units_total_quantity}</p>
      </div>
      {props.cartItem.order_units && (
        <div className={`order-units-list ${props.isExpanded ? 'show' : ''}`}>
          {props.cartItem.order_units.map((unit) => (
            <>
              <div key={unit.id} className="order-unit">
                <img
                  src={
                    mainURL +
                    (unit.unit.images[0]?.image || 'fallback-image-url')
                  }
                  alt=""
                  className="order-unit-img"
                />
                <div className="order-unit-info">
                  <span className="unit-name">{unit.unit.name}</span>
                  <div className="quantity-controls">
                    <button
                      onClick={() =>
                        handleDecreaseUnitAmount(unit.id, unit.quantity)
                      }
                    >
                      -
                    </button>
                    <span>{unit.quantity}</span>
                    <button onClick={() => handleIncreaseUnitAmount(unit.id)}>
                      +
                    </button>
                  </div>
                </div>
                <div className="unit-price">
                  {unit.unit.price} ₸ <span> x 1 шт.</span>
                </div>
              </div>
            </>
          ))}

          <div className="unit-delivery">
            <button
              className={!selfPickup ? 'choosed-delivery-button' : ''}
              onClick={() => {
                setSelfPickup(false);
              }}
            >
              Доставка
            </button>
            <button
              className={selfPickup ? 'choosed-delivery-button' : ''}
              onClick={() => {
                setSelfPickup(true);
              }}
            >
              Самовывоз
            </button>
          </div>

          {selfPickup ? (
            <div className="Order_shop-address">
              Адрес магазина: {props.cartItem.shop.address}
            </div>
          ) : (
            <div className="Order-customer_address-list">
              <p className="delivery-address-title">Адреса доставки:</p>
              {userAddresses.length > 0 ? (
                userAddresses.map((address) => (
                  <label
                    className="Order-customer_address-list-item"
                    key={address.id}
                  >
                    <input
                      name="address"
                      type="radio"
                      onChange={() => updateDefaultAddress(address.id)}
                      value={address.address_string}
                    />
                    {address.address_string}
                  </label>
                ))
              ) : (
                <p className="no-delivery-address-title">
                  Нет доступных адресов.
                </p>
              )}
              {!showMap && (
                <button
                  className="Order-customer_add-address"
                  onClick={() => setShowMap(!showMap)}
                >
                  Добавить новый адрес
                </button>
              )}

              {showMap && (
                <div>
                  <Map
                    onMouseMove={(e: any) => {
                      setNewLatitude(e.lngLat.lat);
                      setNewLongitude(e.lngLat.lng);
                    }}
                    onClick={(e: any) => {
                      setNewLatitude(e.lngLat.lat);
                      setNewLongitude(e.lngLat.lng);
                      fetchAddressByLocation(e.lngLat.lat, e.lngLat.lng);
                    }}
                    initialViewState={{
                      longitude: defaultLongitude,
                      latitude: defaultLatitude,
                      zoom: 15,
                    }}
                    mapboxAccessToken={'pk.eyJ1IjoibWVsYW55Nzc3IiwiYSI6ImNsM2pvdjhlcTA0cTQzcHA2OTVjcnd0amEifQ.qapnEHWqZ89sf3MX8sFJ_Q'}
                    mapStyle="mapbox://styles/mapbox/streets-v9"
                  >
                    <Marker
                      longitude={defaultLongitude}
                      latitude={defaultLatitude}
                      anchor="bottom"
                    >
                      <div>YOU</div>
                    </Marker>
                  </Map>

                  <div className="manual-address-input">
                    <label className="Order-customer_address-label">
                      Адрес:
                    </label>
                    <input
                      onChange={(e: any) => setAddressFromInput(e.target.value)}
                      className="Order-customer_address-input"
                      type="text"
                      placeholder="Укажите номер дома и квартиры"
                    />
                  </div>
                  {addressIsAdded && (
                    <div className=''>
                      Адрес добавлен
                    </div>
                  )}
                  <button
                    onClick={addNewAddress}
                    className="cart-item-add-address-button"
                    disabled={!newLatitude || !newLongitude}
                  >
                    Добавить адрес
                  </button>
                </div>
              )}
            </div>
          )}

          <div className="cart-payment-button">
            <button onClick={handleChangeModalType}>Перейти к оплате</button>
          </div>
        </div>
      )}
    </>
  );
};

export default CartItem;
