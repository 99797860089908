import { Dispatch } from 'redux';
import axios from 'axios';
import { mainURL } from '../config';
import { setProductsAC } from './product-reducer';

export type SubcategoryType = {
  id: number;
  name: string;
  image: string;
  parent_category_info: {
    id: number;
    name: string;
  };
};

export type setSubcategoriesActionType = {
  type: 'SET_SUBCATEGORIES';
  subcategories: SubcategoryType[];
};

export type SubcategoryActionType = setSubcategoriesActionType;

export const SubcategoryReducer = (
  state: SubcategoryType[] = [],
  action: SubcategoryActionType
) => {
  switch (action.type) {
    case 'SET_SUBCATEGORIES':
      return action.subcategories;
    default:
      return state;
  }
};

export const setSubcategoriesAC = (
  subcategories: SubcategoryType[]
): setSubcategoriesActionType => {
  return {
    type: 'SET_SUBCATEGORIES',
    subcategories,
  };
};

export const getSubcategoriesTC = () => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get(
        mainURL + '/en/api/v1/shops/product-subcategories/?filter=for-suppliers'
      );
      console.log('response', response.data.results);
      dispatch(setSubcategoriesAC(response.data.results));
    } catch (error) {
      console.error('Unexpected error:', error);
    }
  };
};

export const getProductsBySubcategoryNameTC = (subcategoryName: string) => {
  return async (dispatch: Dispatch) => {
    const token = localStorage.getItem('accessToken');
    try {
      const response = await axios.get(
        mainURL + `/en/api/v1/shops/products/?subcategory-name=${subcategoryName}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const products = response.data.results.map((product: any) => ({
        id: product.id,
        name: product.name,
        price: product.prices.min, // Use min price for now
        description: product.description,
        is_active: product.is_active,
        // is_new: product.is_new,             // Added new field
        // is_recommended: product.is_recommended, // Added new field
        // is_favourite: product.is_favourite, // Added new field
        image: product.units[0]?.images[0]?.image || '', // Add image if available
        // brand: {
        //     id: product.brand.id,
        //     name: product.brand.name
        // },
        // categories: product.categories.map((category: any) => ({
        //     id: category.id,
        //     name: category.name
        // })),
      }));
      console.log(
        'get products by subcategory name response',
        response.data.results
      );
      dispatch(setProductsAC(products));
    } catch (error) {
      console.error('Unexpected error:', error);
    }
  };
};
