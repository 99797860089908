import { useSelector } from 'react-redux';
import { ProductStateType } from '../../state/product-reducer';
import { RootState } from '../../state/store';
import './product-list.css';
import ProductItem from '../product-item/ProductItem';
import { ShowModalType } from '../show-modal/ShowModal';

type ProductListPropsType = {
  changeModalType: (modalType: ShowModalType) => void
}

const ProductList = (props: ProductListPropsType) => {
  const products = useSelector<RootState, ProductStateType[]>(
    (state: RootState) => state.products
  );

  console.log("products", products);

  return (
    <div className="container">
      <div className="product-list">
        {products.map((product: ProductStateType) => {
          return <ProductItem product={product} key={product.id} changeModalType={props.changeModalType}/>
        })}
      </div>
    </div>
  );
};

export default ProductList;
