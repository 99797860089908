import React, { useState } from 'react';
import Header from '../../components/header/Header';
import CartContainer from '../../components/cart-container/CartContainer';
import ShowModal, {
  ShowModalType,
} from '../../components/show-modal/ShowModal';
import CartCategories from '../../components/cart-categories/CartCategories';

export type cartCategoryType =
  | 'shopping-cart'
  | 'active-orders'
  | 'complete-orders';

const CartPage = () => {
  const [modalType, setModalType] = useState<ShowModalType>('none');
  const [cartCategory, setCartCategory] =
    useState<cartCategoryType>('shopping-cart');

  const handleChangeModalType = (newModalType: ShowModalType) => {
    console.log('change modal', newModalType);
    setModalType(newModalType);
  };

  const handleChangeCategoryType = (categoryType: cartCategoryType) => {
    setCartCategory(categoryType);
  };

  return (
    <div className="cart-page">
      <Header />
      <CartCategories
        cartCategory={cartCategory}
        changeCartCategory={handleChangeCategoryType}
      />
      {cartCategory === 'shopping-cart' ? (
        <CartContainer changeModalType={handleChangeModalType} />
      ) : cartCategory === 'active-orders' ? (
        <div>No Active Orders</div>
      ) : cartCategory === 'complete-orders' ? (
        <div>No Complete Orders</div>
      ) : null}
      <ShowModal
        changeModalType={handleChangeModalType}
        modalType={modalType}
      />
    </div>
  );
};

export default CartPage;
