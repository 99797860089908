import React from 'react'

type RegisterModalPropsType = {
    changeToLogin: (e: any) => void;
}

const RegisterModal = (props: RegisterModalPropsType) => {
    
  return (
    <div className="modal-info">
            <p className="modal-title">Registration Form</p>
            <form className="modal-form">
              <input type="text" placeholder="Username" />
              <input type="text" placeholder="Phone Number" />
              <select className="select-input">
                <option value="" disabled selected>
                  Select city
                </option>
                <option value="Almaty">Almaty</option>
                <option value="Astana">Astana</option>
              </select>
              <p className="register-text">
                Нажимая кнопку Далее вы подтверждаете, что прочитали и согласны
                с Пользовательским соглашением.
              </p>
              <div className="modal-buttons">
                <button type="submit" className="login-button">
                  Register
                </button>
              </div>
              <div className="login-link-text">
                Already have an account? <span onClick={props.changeToLogin}>Log in</span>
              </div>
            </form>
          </div>
  )
}

export default RegisterModal