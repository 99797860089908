import { useEffect, useState } from 'react';
import './show-modal.css';
import LoginModal from './LoginModal';
import RegisterModal from './RegisterModal';
import CreditCardModal from './CreditCardModal';

export type ShowModalType = 'login' | 'register' | 'none' | 'credit-card';

type ShowModalPropsType = {
  modalType: string;
  changeModalType: (modalType: ShowModalType) => void;
};

const ShowModal = (props: ShowModalPropsType) => {
  const closeModal = () => {
    props.changeModalType('none');
  };

  const handleChangeToRegister = (e: any) => {
    e.preventDefault();
    props.changeModalType('register');
  };

  const handleChangeToLogin = (e: any) => {
    e.preventDefault();
    props.changeModalType('login');
  };

  useEffect(() => {
    if (props.modalType === 'none') {
      document.body.style.overflow = 'auto';
    } else {
      document.body.style.overflow = 'hidden';
    }
  }, [props.modalType]);

  return (
    <div className={`modal ${props.modalType !== 'none' ? 'open-modal' : ''}`}>
      <div className="black-background" onClick={closeModal}></div>
      <div
        className={`modal-window ${
          props.modalType === 'none'
            ? ''
            : props.modalType === 'login'
            ? 'open-modal-window login-modal'
            : props.modalType === 'register'
            ? 'open-modal-window register-modal'
            : ''
        }`}
      >
        <div className="close-button" onClick={closeModal}>
          x
        </div>
        {
          props.modalType === 'login' ? <LoginModal changeToRegister={handleChangeToRegister}/> :
          props.modalType ==='register'? <RegisterModal changeToLogin={handleChangeToLogin}/> : 
          props.modalType === 'credit-card' ? <CreditCardModal /> : null
        }
      </div>
      ;
    </div>
  );
};

export default ShowModal;
