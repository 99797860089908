import { useDispatch } from "react-redux";
import {  getProductsBySubcategoryNameTC, SubcategoryType } from "../../state/subcategory-reducer";
import "./category-item.css"

type SubcategoryItemPropsType = {
    subcategory: SubcategoryType;
  };
  

const SubcategoryItem = (props: SubcategoryItemPropsType) => {
    const dispatch = useDispatch<any>();

    const handleChangeSubcategory = () => {
      dispatch(getProductsBySubcategoryNameTC(props.subcategory.name));
    }; 
  
    return (
      <div className="category-item">
        <img src={props.subcategory.image} alt="" onClick={handleChangeSubcategory} />
        <p>{props.subcategory.name}</p>
      </div>
    );
}

export default SubcategoryItem