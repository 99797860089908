import { useDispatch, useSelector } from 'react-redux';
import { ProductStateType } from '../../state/product-reducer';
import { useAuth } from '../hoc/useAuth';
import './product-item.css';
import {
  AddToCartTC,
  CartItemType,
  decreaseUnitAmountTC,
  getCartItemsTC,
  increaseUnitAmountTC,
  removeFromCartTC,
} from '../../state/cart-reducer';
import { ShowModalType } from '../show-modal/ShowModal';
import { RootState } from '../../state/store';
import { mainURL } from '../../config';
import { useState } from 'react';

type ProductItemPropsType = {
  changeModalType: (modalType: ShowModalType) => void;
  product: ProductStateType;
};

const ProductItem = (props: ProductItemPropsType) => {
  const [cardBackSide, setCardBackSide] = useState(false);
  const cartItems = useSelector<RootState, CartItemType[]>(
    (state) => state.cartItems
  );
  const { isAuthenticated, logout } = useAuth();
  const dispatch = useDispatch<any>();

  const existingCartItem = cartItems.find(
    (item) =>
      item.order_units &&
      item.order_units.some(
        (orderUnit) => orderUnit.unit.id === props.product.id
      )
  );

  const productQuantity = existingCartItem
    ? existingCartItem.order_units.find(
        (orderUnit) => orderUnit.unit.id === props.product.id
      )?.quantity || 0
    : 0;

  const existingCartItemId = existingCartItem
    ? existingCartItem.order_units.find(
        (orderUnit) => orderUnit.unit.id === props.product.id
      )?.id || 0
    : 0;
  const handleChangeModalType = (e: any) => {
    e.preventDefault();

    props.changeModalType('login');
  };

  const handleAddToCard = () => {
    dispatch(AddToCartTC(props.product.id));
    dispatch(getCartItemsTC());
  };

  const handleIncreaseUnitAmount = () => {
    dispatch(increaseUnitAmountTC(existingCartItemId));
  };
  const handleDecreaseUnitAmount = () => {
    if (productQuantity === 1) {
      dispatch(removeFromCartTC(existingCartItemId));
    } else {
      dispatch(decreaseUnitAmountTC(existingCartItemId));
    }
  };

  return (
    <div className={`product-item ${cardBackSide ? "more-info" : ""}`}>
      <div className="flip-card-inner">
        <div className="flip-card-front">
          <img src={mainURL + props.product.image} alt="" />
          <div className="product-info">
            <p className="product-price">{props.product.price} ₸</p>
            <p className="product-name">{props.product.name}</p>
            <div className="product-item-buttons">
              <button className="show-more-button" onClick={() => setCardBackSide(true)}>Подробнее</button>
              {existingCartItem ? (
                <div className="product-quantity-controls">
                  <button onClick={handleDecreaseUnitAmount}>-</button>
                  <span>{productQuantity}</span>
                  <button onClick={handleIncreaseUnitAmount}>+</button>
                </div>
              ) : (
                <button
                  className="add-to-cart-button"
                  onClick={
                    isAuthenticated ? handleAddToCard : handleChangeModalType
                  }
                >
                  В корзину
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="flip-card-back" onClick={() => setCardBackSide(false)}>
          <p>Описание товара</p>

          <p>{props.product.description}</p>
        </div>
      </div>
    </div>
    
  );
};

export default ProductItem;
