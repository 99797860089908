import './main-page.css';
import CategoryList from '../../components/category-list/CategoryList';
import ProductList from '../../components/product-list/ProductList';
import { useEffect, useState } from 'react';
import LoginModal, {
  ShowModalType,
} from '../../components/show-modal/ShowModal';
import ShowModal from '../../components/show-modal/ShowModal';
import Header from '../../components/header/Header';
import { useLocation } from 'react-router-dom';
import SubcategoryList from '../../components/category-list/SubcategoryList';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import { SubcategoryType } from '../../state/subcategory-reducer';
import {
  CategoryStateType,
  CategoryType,
  setActiveCategoryAC,
} from '../../state/category-reducer';

const MainPage = () => {
  const dispatch = useDispatch<any>();
  const [modalType, setModalType] = useState<ShowModalType>('none');

  const { activeCategory: activeCategory, categories: allCategories } =
    useSelector<RootState, CategoryStateType>((state) => state.categories);

  const allSubcategories = useSelector<RootState, SubcategoryType[]>(
    (state) => state.subcategories || []
  );

  const handleChangeModalType = (newModalType: ShowModalType) => {
    console.log('change modal', newModalType);
    setModalType(newModalType);
  };

  console.log('allSubcategories', allSubcategories);
  const newSubcategories = allSubcategories.filter((subcategory) => {
    if (activeCategory && activeCategory.id) {
      return subcategory.parent_category_info.id === activeCategory.id;
    }
    return false;
  });

  console.log('new categories', newSubcategories);

  useEffect(() => {
    // localStorage.removeItem('accessToken');
  }, []);

  return (
    <div className="main-page">
      <Header changeModal={handleChangeModalType} />
      <div className="main-container">
        <CategoryList activeCategory={activeCategory} />
        <SubcategoryList subcategories={newSubcategories} />
        <ProductList changeModalType={handleChangeModalType} />
        <ShowModal
          modalType={modalType}
          changeModalType={handleChangeModalType}
        />
      </div>
    </div>
  );
};

export default MainPage;
