import axios from 'axios';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { mainURL } from '../config';

export type Location = {
  lat: number;
  lng: number;
};

export type Shop = {
  id: number;
  name: string;
  owner_id: number;
  logo: string;
  background_image: string;
  address: string;
  location: Location;
  basic_currency: number;
  phone_numbers: string[];
};

export type Unit = {
  id: number;
  name: string;
  old_price: number;
  price: number;
  images: Array<{ id: number; image: string }>;
  product_id: number;
  description: string;
  num_in_stock: number;
};

export type OrderUnit = {
  id: number;
  unit: Unit;
  quantity: number;
  final_price: number;
};

export type Customer = {
  id: number;
  name: string;
  phone_number: string;
  profile_picture: string;
};

export type DeliveryAddress = {
  id: number;
  address_string: string;
  detail_info: string;
  is_default: boolean;
  lat: number;
  lng: number;
};

export type CartItemType = {
  id: number;
  shop: Shop;
  customer: Customer;
  delivery_address: DeliveryAddress;
  self_pickup: boolean;
  units_total_quantity: number;
  order_units: OrderUnit[];
  different_units_num: number;
  status: number;
  order_total_price: number;
  delivery_price: number;
  grand_total_with_delivery: number;
};

type AddToCartActionType = {
  type: 'ADD_TO_CART';
  cartItem: CartItemType;
};

type SetCartItemsActionType = {
  type: 'SET_CART_ITEMS';
  cartItems: CartItemType[];
};

type UpdateUnitInfoActionType = {
  type: 'UPDATE_UNIT_INFO';
  unitId: number;
  unitDetails: Unit;
};

type IncreaseUnitAmountActionType = {
  type: 'INCREASE_UNIT_AMOUNT';
  unitId: number;
};

type DecreaseUnitAmountActionType = {
  type: 'DECREASE_UNIT_AMOUNT';
  unitId: number;
};

type removeFromCartActionType = {
  type: 'REMOVE_FROM_CART';
  unitId: number;
};

export type CartReducerActionType =
  | AddToCartActionType
  | SetCartItemsActionType
  | UpdateUnitInfoActionType
  | IncreaseUnitAmountActionType
  | DecreaseUnitAmountActionType
  | removeFromCartActionType;

export const CartReducer = (
  state: CartItemType[] = [],
  action: CartReducerActionType
) => {
  switch (action.type) {
    case 'SET_CART_ITEMS':
      console.log('Setting CartItems action', action.cartItems);
      return action.cartItems;
    case 'ADD_TO_CART':
      return [...state, action.cartItem];

    case 'INCREASE_UNIT_AMOUNT':
      const increasedState = state.map((cartItem) => {
        const updatedUnits = cartItem.order_units.map((orderUnit) => {
          if (orderUnit.id === action.unitId) {
            const newQuantity = orderUnit.quantity + 1;
            return {
              ...orderUnit,
              quantity: newQuantity,
              final_price: newQuantity * orderUnit.unit.price,
            };
          }
          return orderUnit;
        });

        const newOrderTotalPrice = updatedUnits.reduce(
          (total, unit) => total + unit.final_price,
          0
        );

        return {
          ...cartItem,
          order_units: updatedUnits,
          units_total_quantity: updatedUnits.reduce(
            (total, unit) => total + unit.quantity,
            0
          ),
          order_total_price: newOrderTotalPrice,
        };
      });

      return increasedState;

    case 'DECREASE_UNIT_AMOUNT':
      const decreasedState = state.map((cartItem) => {
        const updatedUnits = cartItem.order_units
          .map((orderUnit) => {
            if (orderUnit.id === action.unitId) {
              const newQuantity = orderUnit.quantity - 1;
              return {
                ...orderUnit,
                quantity: newQuantity,
                final_price: newQuantity * orderUnit.unit.price,
              };
            }
            return orderUnit;
          })
          .filter((unit) => unit.quantity > 0);

        const newOrderTotalPrice = updatedUnits.reduce(
          (total, unit) => total + unit.final_price,
          0
        );

        return {
          ...cartItem,
          order_units: updatedUnits,
          units_total_quantity: updatedUnits.reduce(
            (total, unit) => total + unit.quantity,
            0
          ),
          order_total_price: newOrderTotalPrice,
        };
      });

      return decreasedState;

    case 'REMOVE_FROM_CART':
      const updatedState = state
        .map((cartItem) => {
          const updatedUnits = cartItem.order_units.filter(
            (orderUnit) => orderUnit.id !== action.unitId
          );

          const newOrderTotalPrice = updatedUnits.reduce(
            (total, unit) => total + unit.final_price,
            0
          );

          return {
            ...cartItem,
            order_units: updatedUnits,
            units_total_quantity: updatedUnits.reduce(
              (total, unit) => total + unit.quantity,
              0
            ),
            order_total_price: newOrderTotalPrice,
          };
        })
        .filter((cartItem) => cartItem.order_units.length > 0);

      return updatedState;

    default:
      return state;
  }
};

export const setCartItemsAC = (
  cartItems: CartItemType[]
): SetCartItemsActionType => {
  return {
    type: 'SET_CART_ITEMS',
    cartItems,
  };
};

export const AddToCartAC = (
  cartItemData: CartItemType
): AddToCartActionType => {
  const cartItem: CartItemType = {
    id: cartItemData.id,
    shop: cartItemData.shop,
    customer: cartItemData.customer,
    delivery_address: cartItemData.delivery_address,
    self_pickup: cartItemData.self_pickup,
    units_total_quantity: cartItemData.units_total_quantity,
    order_units: cartItemData.order_units.map((unit: any) => ({
      id: unit.id,
      unit: {
        id: unit.unit.id,
        name: unit.unit.name,
        old_price: unit.unit.old_price,
        price: unit.unit.price,
        images: unit.unit.images,
        product_id: unit.unit.product_id,
        description: unit.unit.description,
        num_in_stock: unit.unit.num_in_stock,
      },
      quantity: unit.quantity,
      final_price: unit.final_price,
    })),
    different_units_num: cartItemData.different_units_num,
    status: cartItemData.status,
    order_total_price: cartItemData.order_total_price,
    delivery_price: cartItemData.delivery_price,
    grand_total_with_delivery: cartItemData.grand_total_with_delivery,
  };

  return {
    type: 'ADD_TO_CART',
    cartItem,
  };
};

export const increaseUnitAmountAC = (
  id: number
): IncreaseUnitAmountActionType => {
  return {
    type: 'INCREASE_UNIT_AMOUNT',
    unitId: id,
  };
};

export const decreaseUnitAmountAC = (
  id: number
): DecreaseUnitAmountActionType => {
  return {
    type: 'DECREASE_UNIT_AMOUNT',
    unitId: id,
  };
};

export const removeFromCartAC = (id: number): removeFromCartActionType => {
  return {
    type: 'REMOVE_FROM_CART',
    unitId: id,
  };
};

export const getCartItemsTC = () => {
  return async (dispatch: Dispatch) => {
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      console.error('No access token found');
      return;
    }

    try {
      const response = await axios.get(  mainURL + '/en/api/v1/shops/shopping-cart/', {
        headers: {
          Authorization: 'Bearer ' + accessToken,
          'Content-Type': 'application/json',
        },
      });

      console.log('Cart items fetched successfully', response);

      const cartItems = response.data;

      const fetchPromises = cartItems.map(async (item: CartItemType) => {
        const newItem = await fetchUnitDetails(item.id);
        return newItem;
      });

      const resolvedItems = await Promise.all(fetchPromises);

      dispatch(setCartItemsAC(resolvedItems));
    } catch (error) {
      console.error('Unexpected error:', error);
    }
  };
};

export const AddToCartTC = (id: number) => {
  return async (dispatch: Dispatch) => {
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      console.error('No access token found');
      return;
    }

    try {
      const response = await axios.post(
          mainURL + '/en/api/v1/shops/add-to-cart/',
        { unit_id: id },
        {
          headers: {
            Authorization: 'Bearer ' + accessToken,
            'Content-Type': 'application/json',
          },
        }
      );

      console.log('Item added successfully', response);
    } catch (error) {
      console.error('Error adding to cart:', error);
    }
  };
};

export const removeFromCartTC = (id: number) => {
  return async (dispatch: Dispatch) => {
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      console.error('No access token found');
      return;
    }

    try {
      const response = await axios.delete(  mainURL + '/en/api/v1/shops/order-units/', {
        headers: {
          Authorization: 'Bearer ' + accessToken,
          'Content-Type': 'application/json',
        },
        data: {
          order_unit_id: id,
        },
      });

      console.log('Item removed successfully', response);
      dispatch(removeFromCartAC(id));
    } catch (error) {
      console.error('Error removing from cart:', error);
    }
  };
};

export const increaseUnitAmountTC = (id: number) => {
  return async (dispatch: Dispatch) => {
    console.log('increasing id', id);
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      console.error('No access token found');
      return;
    }

    try {
      const response = await axios.post(
          mainURL + '/en/api/v1/shops/order-units/increase/',
        { order_unit_id: id },
        {
          headers: {
            Authorization: 'Bearer ' + accessToken,
            'Content-Type': 'application/json',
          },
        }
      );

      console.log('Unit amount increased successfully', response);
      dispatch(increaseUnitAmountAC(id));
    } catch (error) {
      console.error('Error increasing unit amount:', error);
    }
  };
};

export const decreaseUnitAmountTC = (id: number) => {
  return async (dispatch: Dispatch) => {
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      console.error('No access token found');
      return;
    }

    try {
      const response = await axios.post(
         mainURL + '/en/api/v1/shops/order-units/decrease/',
        { order_unit_id: id },
        {
          headers: {
            Authorization: 'Bearer ' + accessToken,
            'Content-Type': 'application/json',
          },
        }
      );

      console.log('Unit amount decreased successfully', response);
      dispatch(decreaseUnitAmountAC(id));
    } catch (error) {
      console.error('Error decreasing unit amount:', error);
    }
  };
};

export const fetchUnitDetails = async (unitId: number) => {
  const accessToken = localStorage.getItem('accessToken');

  if (!accessToken) {
    console.error('No access token found');
    return;
  }

  try {
    const response = await axios.get(mainURL + `/en/api/v1/shops/orders/${unitId}/`, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-Type': 'application/json',
      },
    });

    console.log('Unit details fetched successfully', response.data);

    return response.data;
  } catch (error) {
    console.error('Error fetching unit details:', error);
  }
};
