import { useDispatch } from 'react-redux';
import {
  CategoryType,
  getSubcategoriesByIdTC,
  getCategoriesTC,
  setActiveCategoryAC,
  setCategoriesAC,
} from '../../state/category-reducer';
import './category-item.css';

type CategoryItemPropsType = {
  category: CategoryType;
  isActive: boolean;

};

const CategoryItem = (props: CategoryItemPropsType) => {
  const dispatch = useDispatch<any>();

  const handleChangeCategory = (newCategory: CategoryType) => {
    dispatch(setActiveCategoryAC(newCategory));
  }; 

  return (
    <div className="category-item">
      <img src={props.category.image} alt="" onClick={() => handleChangeCategory(props.category)} className={props.isActive ? "active" : ""}/>
      <p>{props.category.name}</p>
    </div>
  );
};

export default CategoryItem;
