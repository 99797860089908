import axios from 'axios';
import React, { useEffect } from 'react';
import { makePaymentRequest } from '../utils/fetch';
import { useParams } from 'react-router-dom';

const CreditCardModal = () => {
  const {orderId} = useParams();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.tiptoppay.kz/checkout.js';
    script.async = true;
    script.onload = () => {
      if (!window.tiptop) {
        console.error('TipTop Pay checkout script is not loaded');
      }
    };
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!window.tiptop) {
      console.error('TipTop Pay checkout script is not loaded');
      return;
    }

    const checkout = new window.tiptop.Checkout({
      publicId: 'pk_cf85675adc462dbe96083259857ea',
      container: document.getElementById('paymentFormSample'),
    });

    try {
      const cryptogram = await checkout.createPaymentCryptogram();
      console.log('Cryptogram:', cryptogram);

      const nameInput = document.querySelector('input[data-cp="name"]') as HTMLInputElement;
      const name = nameInput ? nameInput.value : '';

      makePaymentRequest(cryptogram, name, Number(orderId));

      console.log('Payment processed successfully');
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <form className="credit-card" onSubmit={handleSubmit} autoComplete="off">
      <div className="form-header">
        <h4 className="title">Информация о карте</h4>
      </div>

      <div className="form-body">
        <input
          type="text"
          className="card-number"
          placeholder="Имя"
          data-cp="name"
        />
        <input
          type="text"
          className="card-number"
          placeholder="Номер карты"
          data-cp="cardNumber"
        />

        <div className="date-field">
          <div className="month">
            <select data-cp="expDateMonth">
              <option value="1">Январь</option>
              <option value="2">Февраль</option>
              <option value="3">Март</option>
              <option value="4">Апрель</option>
              <option value="5">Май</option>
              <option value="6">Июнь</option>
              <option value="7">Июль</option>
              <option value="8">Август</option>
              <option value="9">Сентябрь</option>
              <option value="10">Октябрь</option>
              <option value="11">Ноябрь</option>
              <option value="12">Декабрь</option>
            </select>
          </div>
          <div className="year">
            <select data-cp="expDateYear">
              <option value="2024">2024</option>
              <option value="2025">2025</option>
              <option value="2026">2026</option>
              <option value="2027">2027</option>
              <option value="2028">2028</option>
              <option value="2029">2029</option>
              <option value="2030">2030</option>
              <option value="2031">2031</option>
              <option value="2032">2032</option>
              <option value="2033">2033</option>
              <option value="2034">2034</option>
            </select>
          </div>
        </div>

        <div className="card-verification">
          <div className="cvv-input">
            <input type="text" placeholder="CVV" data-cp="cvv" />
          </div>
          <div className="cvv-details">
            <p>
              3 или 4 цифры обычно указаны <br /> на полосе для подписи
            </p>
          </div>
        </div>

        <button type="submit" className="proceed-btn">
          Продолжить
        </button>
      </div>
    </form>
  );
};

export default CreditCardModal;
