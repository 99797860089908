import { cartCategoryType } from '../../pages/cart/CartPage';
import './cart-categories.css';

type cartCategoriesPropsType = {
  cartCategory: cartCategoryType;
  changeCartCategory: (categoryType: cartCategoryType) => void;
};

const CartCategories = (props: cartCategoriesPropsType) => {
  const handleChangeCategoryType = (categoryType: cartCategoryType) => {
    console.log('change category', categoryType);
    props.changeCartCategory(categoryType);
  };



  return (
    <div className="cart-categories">
      <ul className="cart-categories-list">
        <li
          className={props.cartCategory === 'shopping-cart' ? 'active' : ''}
          onClick={() => handleChangeCategoryType('shopping-cart')}
        >
          Корзина
        </li>
        <li
          className={props.cartCategory === 'active-orders' ? 'active' : ''}
          onClick={() => handleChangeCategoryType('active-orders')}
        >
          Активные заказы
        </li>
        <li
          className={props.cartCategory === 'complete-orders' ? 'active' : ''}
          onClick={() => handleChangeCategoryType('complete-orders')}
        >
          Выполненные заказы
        </li>
      </ul>
    </div>
  );
};

export default CartCategories;
