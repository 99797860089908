import { useSelector } from 'react-redux';
import './cart-container.css';
import { RootState } from '../../state/store';
import { CartItemType } from '../../state/cart-reducer';
import CartItem from '../cart-item/CartItem';
import { useNavigate } from 'react-router-dom';
import { ShowModalType } from '../show-modal/ShowModal';
import { useState } from 'react';

type CartContainerPropsType = {
  changeModalType: (newModalType: ShowModalType) => void;
};

const CartContainer = (props: CartContainerPropsType) => {
  const navigate = useNavigate();
  const [expandedItemId, setExpandedItemId] = useState<number | null>(null);
  const cartItems = useSelector<RootState, CartItemType[]>(
    (state) => state.cartItems
  );
  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  const filteredCartItems = cartItems.filter((item) =>
    selectedItems.includes(item.id)
  );

  const totalOrderPrice = filteredCartItems.reduce(
    (total, cartItem) => total + cartItem.order_total_price,
    0
  );
  const totalOrderAmount = filteredCartItems.reduce(
    (total, cartItem) => total + cartItem.units_total_quantity,
    0
  );
  const isAllSelected = selectedItems.length === cartItems.length;

  const handleChangeModalType = () => {
    props.changeModalType('credit-card');
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelectedItems(cartItems.map((item) => item.id));
    } else {
      setSelectedItems([]);
    }
  };

  const handleItemSelect = (id: number) => {
    setSelectedItems((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((itemId) => itemId !== id)
        : [...prevSelected, id]
    );
  };

  const handleToggleUnits = (itemId: number) => {
    setExpandedItemId((prev) => (prev === itemId ? null : itemId));
  };

  return (
    <div className="cart-container">
      {cartItems.length > 0 && (
        <div className="container">
          <p className="cart-title">Корзина</p>
          <div className="cart-container-info">
            <div className="cart-items">
              <div className="cart-items-list">
                {cartItems.map((item) => (
                  <CartItem
                    onToggleUnits={() => handleToggleUnits(item.id)}
                    isAnyExpanded={expandedItemId !== null}
                    key={item.id}
                    cartItem={item}
                    isSelected={selectedItems.includes(item.id)}
                    onSelect={() => handleItemSelect(item.id)}
                    changeModalType={props.changeModalType}
                    isExpanded={expandedItemId === item.id}
                  />
                ))}
              </div>
            </div>
            {/* <div className="cart-cost">
              <div className="cart-total">
                <div className="your-cart">
                  <p className="your-cart-title">Your cart</p>
                  <p className="your-cart-total">{totalOrderAmount} items</p>
                </div>

                <hr />
                <div className="total-sum">
                  <p className="total-sum-title">Total sum</p>
                  <p className="total-sum-number">{totalOrderPrice} KZT</p>
                </div>
              </div>

              <div className="cart-payment-button">
                <button onClick={handleChangeModalType}>Go to payment</button>
              </div>
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default CartContainer;
