import { Dispatch } from "redux";
import axios from "axios";
import { mainURL } from "../config";
import { setProductsAC } from "./product-reducer";
import { setSubcategoriesAC } from "./subcategory-reducer";

export type CategoryType = {
    id: number;
    name: string;
    image: string;
}

export type CategoryStateType = {
    categories: CategoryType[];
    activeCategory: CategoryType;
}

export type setCategoriesActionType = {
    type: 'SET_CATEGORIES';
    categories: CategoryType[];
}

export type setActiveCategoryActionType = {
    type: 'SET_ACTIVE_CATEGORY';
    category: CategoryType;
}

const initialState: CategoryStateType = {
    categories: [],
    activeCategory: {} as CategoryType 
  };

export type CategoryActionType = setCategoriesActionType | setActiveCategoryActionType;

export const CategoryReducer = (state: CategoryStateType = initialState, action: CategoryActionType) => {
    switch (action.type) {
        case 'SET_CATEGORIES':
            return {...state, categories: action.categories}
        case 'SET_ACTIVE_CATEGORY':
            return {...state, activeCategory: action.category }
        default:
            return state;
    }
}

export const setCategoriesAC = (categories: CategoryType[]): setCategoriesActionType => {
    return {
        type: 'SET_CATEGORIES',
        categories
    }
}

export const setActiveCategoryAC = (category: CategoryType): setActiveCategoryActionType => {
    return {
        type: 'SET_ACTIVE_CATEGORY',
        category
    }
}

export const getCategoriesTC = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axios.get(mainURL + '/en/api/v1/shops/product-categories/?filter=for-suppliers');
            console.log("response", response.data.results);
            dispatch(setCategoriesAC(response.data.results));
            dispatch(setActiveCategoryAC(response.data.results[0]));
        } catch(error) {
            console.error('Unexpected error:', error);
        }
    }
}


export const getSubcategoriesByIdTC = (categoryId: number) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axios.get(mainURL + `/en/api/v1/shops/product-subcategories/?parent-category-id=${categoryId}`,{
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
                }
            });
            console.log("get subcategories by category id response", response.data);
            dispatch(setSubcategoriesAC(response.data.results));
        } catch(error) {
            console.error('Unexpected error:', error);
        }
    }
}