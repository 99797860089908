import './header.css';
import logo from '../../static/images/stubla_logo.png';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { useEffect, useState } from 'react';
import { useAuth } from '../hoc/useAuth';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import { CartItemType } from '../../state/cart-reducer';
import { useNavigate } from 'react-router-dom';

type HeaderPropsType = {
  changeModal?: (modalType: 'login' | 'register' | 'none') => void;
};

const Header = (props: HeaderPropsType) => {
  const cartItems = useSelector<RootState, CartItemType[]>(
    (state) => state.cartItems
  );
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();

  const changeModalType = (e: any) => {
    e.preventDefault();
    props.changeModal && props.changeModal(e.currentTarget.className);
  };

  return (
    <>
      <div className="header desktop">
        <div className="header-content">
          <NotificationsNoneOutlinedIcon
            className="notification-icon"
            fontSize="large"
          />
          <div className="main-logo desktop" onClick={() => navigate('/')}>
            <img src={logo} alt="logo" className="" />
          </div>
          <div className="log-buttons">
            {isAuthenticated ? (
              <>
                <div
                  className="shopping-cart-logo"
                  onClick={() => navigate('/shopping-cart')}
                >
                  <ShoppingCartOutlinedIcon
                    className="cart-icon"
                    fontSize="large"
                  />
                  <div className="shopping-cart-amount">
                    <p>{cartItems.length}</p>
                  </div>
                </div>
                <button className="login" onClick={logout}>
                  Выйти
                </button>
              </>
            ) : (
              <>
                <button className="login" onClick={changeModalType}>
                  Войти
                </button>
                {/* <button className="register" onClick={changeModalType}>Register</button> */}
              </>
            )}
          </div>
        </div>
      </div>

      <div className="header mobile">
        <div className="header-content">
          <img
            src={logo}
            alt="logo"
            className="main-logo mobile"
            onClick={() => navigate('/')}
          />
          <div className="log-buttons">
            {isAuthenticated ? (
              <>
                <div
                  className="shopping-cart-logo"
                  onClick={() => navigate('/shopping-cart')}
                >
                  <ShoppingCartOutlinedIcon
                    className="cart-icon"
                    fontSize="large"
                  />
                  <div className="shopping-cart-amount">
                    <p>{cartItems.length}</p>
                  </div>
                </div>
                <button className="login" onClick={logout}>
                  Выйти
                </button>
              </>
            ) : (
              <>
                <button className="login" onClick={changeModalType}>
                  Войти
                </button>
                {/* <button className="register" onClick={changeModalType}>Register</button> */}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
