import { useSelector } from 'react-redux';
import './category-list.css';
import { RootState } from '../../state/store';
import SubcategoryItem from '../category-item/SubcategoryItem';
import { SubcategoryType } from '../../state/subcategory-reducer';

type SubcategoryListPropsType = {
  subcategories: Array<SubcategoryType>;
};

const SubcategoryList = (props: SubcategoryListPropsType) => {
  return (
    <div className="categories">
        <div className="category-list">
          {props.subcategories.length > 0 ? (
            props.subcategories.map((subcategory) => {
              return (
                <SubcategoryItem
                  subcategory={subcategory}
                  key={subcategory.id}
                />
              );
            })
          ) : (
            <p>No subcategories available</p>
          )}
        </div>
    </div>
  );
};

export default SubcategoryList;
