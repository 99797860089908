import axios from 'axios';
import { mainURL } from '../../config';

export const verifyPhoneNumber = async (phoneNumber: string) => {
  try {
    const response = await axios.post(
      mainURL + '/en/api/v1/send-login-code/',
      { phone_number: phoneNumber },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    console.log('Response:', response.data);

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data?.message || 'Login failed');
    } else {
      throw new Error('Login failed: Network or server error');
    }
  }
};

export const loginByCode = async (phoneNumber: string, confirmationCode: string) => {
  try {
    const response = await axios.post(
      mainURL + '/en/api/v1/login-by-code/',
      { password: confirmationCode, phone_number: phoneNumber},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    localStorage.setItem('accessToken', response.data.access);
    localStorage.setItem('refreshToken', response.data.refresh);

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data?.message || 'Verification failed');
    } else {
      throw new Error('Verification failed: Network or server error');
    }
  }
}

export const sendRegisterRequest = async (
  username: string,
  phoneNumber: string,
  city: string
) => {
  try {
    const response = await fetch('/api/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, phoneNumber, city }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Registration failed');
    }

    const data = await response.json();
    return data.token;
  } catch (error) {
    throw new Error(
      error instanceof Error ? error.message : 'Registration failed'
    );
  }
};


export const makePaymentRequest = async (cryptogram: string, cardName: string, orderId: number) => {
  try {
    const token = localStorage.getItem('accessToken'); 
    const orderData = {
      order_id: orderId,
      card_cryptogram: cryptogram,
      name: cardName, 
      save_card: true, 
    };

    const response = await axios.post(
      mainURL + '/en/api/v1/shops/cloudpayments-checkout/',
      orderData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
    );

    console.log('Payment processed successfully:', response.data);
  }catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 303) {
        console.log('Redirection URL:', error.response.headers.location);
      }
      console.error('Error processing payment:', error.response?.data || error.message);
    } else {
      console.error('Unexpected error:', error);
    }
  }
};

export const fetchWithAuthCheck = async (url: string, options: any) => {
  const requestUrl = `${mainURL}${url}`;
  const token = localStorage.getItem('accessToken');

  if (token) {
    options.headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    try {
      const response = await axios(requestUrl, options);
      return response;
    } catch (error: any) {
      if (error.response?.status === 401) {
        // Attempt to refresh tokens
        const newTokensResponse = await updateTokens();
        if (newTokensResponse.success) {
          // Retry the original request with the new access token
          options.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
          const newResponse = await axios(requestUrl, options);
          return newResponse;
        } else {
          throw new Error('Token refresh failed');
        }
      } else {
        throw error; // Rethrow the error for further handling
      }
    }
  } else {
    throw new Error('No access token found');
  }
};


export const updateTokens = async () => {
  try {
    const response = await axios.post(`${mainURL}/refresh-token/`, {
      refresh: localStorage.getItem('refreshToken'),
    }, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      console.log('Token updated successfully', response)
      localStorage.setItem('accessToken', response.data.access);
      localStorage.setItem('refreshToken', response.data.refresh);
      return { success  : true, message: "Ok" };
    }
    return { success: false, message: response.statusText };
  } catch (e: any) {
    console.error(e);
    return { success: false, message: e.message };
  }
};